<template>
  <svg height="30" width="100%">
    <text class="semi-bold" x="4" y="22">Emissary</text>
  </svg>
</template>
<script setup>
const props = defineProps({
  dark: { type: Boolean, default: false }
})
const textColor = computed(() => props.dark ? 'white' : 'rgb(var(--v-theme-neutral-lighten-2))')
</script>
<style lang="scss" scoped>
  text {
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 32px;
    fill: v-bind(textColor);
  }
</style>
