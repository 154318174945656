<template>
  <div class="d-flex align-center" :class="{'cursor-pointer': !disableRedirect}" @click="redirectIfLogged">
    <img :src="dark ? '/logo_dark.svg' : '/logo.svg'" class="default-size" alt="Emissary logo">
    <EmEmissarySvg v-if="!dense" class="pl-1" :dark="dark" />
  </div>
</template>

<script setup>
const readableUser = useUserStore().readable
const router = useRouter()
const props = defineProps({
  dark: { type: Boolean, default: false },
  disableRedirect: { type: Boolean, default: false },
  dense: { type: Boolean, default: false }
})
const redirectIfLogged = () => {
  if (!props.disableRedirect && readableUser.userInfo) {
    router.push('/')
  }
}
</script>

<style scoped lang="scss">
.default-size {
  height: 32px;
}
</style>
